import React, { useEffect } from "react";
 
import DetailTab from "./detailsTab/details";
 
const PersonalDetails = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
 
  return (
    <div>
     

      <div className="container">
              <DetailTab />
            </div>
    </div>
  );
};

export default PersonalDetails;
