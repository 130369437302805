import React, { useEffect } from "react";

import ProfileSurvey from "./profilesurveycomponent";

const ProfileSurveyPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="container">
        <ProfileSurvey />
      </div>
    </div>
  );
};

export default ProfileSurveyPage;
