import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { RightSquareTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import { Table, Col, Row, Card, Button, Avatar, Typography } from "antd";
import { listReferrals } from "../../../redux/slice/rewardSlice";
import ReferralTransactionTable from "../ReferralTransactionTable";
import rewardService from "../../../services/rewardService";
import "./index.css";

const { Title } = Typography;
export default function ListReferralsTable() {
  const dispatch = useDispatch();

  const [referralTransactions = null, setReferralTransactions] = useState(null);
  const [flag, setFlag] = useState(false);
  useEffect(() => {
    dispatch(
      listReferrals({ filters: {}, page: 1, order_by: ["-created_at"] })
    );
  }, [dispatch]);

  const { listReferralsData = {}, fetchingRefferalData } = useSelector(
    ({ reward }) => reward
  );
  const { items = [], pagination = {} } = listReferralsData;
  const { total = 20, page = 1, page_size = 5 } = pagination;
  let tableParams = {
    pagination: { current: page, pageSize: page_size, total: total },
  };

  const handleTablePageChange = (pagination, filters, sorter) => {
    tableParams.pagination.current = pagination.current;
    dispatch(
      listReferrals({
        filters: {},
        page: pagination.current,
        order_by: ["-created_at"],
      })
    );
  };
  const handleClose = () => {
    setFlag(false);
  };

  const onRowSelect = (record) => {
    rewardService
      .listRefferalTransaction({
        filters: {},
        page: 1,
        order_by: ["-created_at"],
        referee_id: record.referee_id,
      })
      .then((response) => {
        setReferralTransactions({ referee: record, data: response.data });
        setFlag(true);
      });
  };
  const columns = [
    {
      title: <Title level={5}> Name</Title>,
      dataIndex: "referee_email",
      key: "referee_email",

      render: (data, record) => (
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            <p style={{ fontSize: "12px" }}>{data}: </p>
            <p
              style={{
                fontWeight: "bold",
                marginLeft: "1px",
                fontSize: "12px",
              }}
            >
              {record.referee_earn_benefit.INR} INR
            </p>
          </div>
        </>
      ),
    },
    {
      title: <Title level={5}> Points</Title>,

      dataIndex: "referee_earn_benefit",
      key: "referee_earn_benefit2",

      render: (data, record) => (
        <Button
          type="text"
          icon={
            flag ? (
              <CloseCircleTwoTone twoToneColor="#ff0000" />
            ) : (
              <RightSquareTwoTone />
            )
          }
          size="large"
          onClick={flag ? handleClose : () => onRowSelect(record)}
        />
      ),
    },
    {
      title: <Title level={5}> Date&Time</Title>,

      dataIndex: "referee_earn_benefit",
      key: "referee_earn_benefit2",

      render: (data, record) => (
        <Button
          type="text"
          icon={
            flag ? (
              <CloseCircleTwoTone twoToneColor="#ff0000" />
            ) : (
              <RightSquareTwoTone />
            )
          }
          size="large"
          onClick={flag ? handleClose : () => onRowSelect(record)}
        />
      ),
    },
  ];

  return (
    <>
      <div className="site-card-wrapperr2" style={{ marginTop: "2%" }}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Card
              bordered={false}
              title={<Title level={4}>My Referral Earnings</Title>}
              className="criclebox tablespace mb-24"
            >
              <div className="table-responsive2">
                <div className="table-container2">
                  <Table
                    columns={columns}
                    dataSource={items}
                    pagination={tableParams}
                    loading={fetchingRefferalData}
                    rowKey={(record) => record.referee_email}
                    onChange={handleTablePageChange}
                    className="ant-border-space"
                  />
                </div>

                {flag && referralTransactions && (
                  <ReferralTransactionTable
                    referralTransactions={referralTransactions}
                  />
                )}
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
