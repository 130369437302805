import { Typography, Table } from "antd";
import React, {useEffect} from "react";
const { Title, Paragraph, Text } = Typography;

const columns = [
  {
    title: "Activity",
    dataIndex: "activity",
    key: "activity",
    render: (text) => <a>{text}</a>,
  },
  {
    title: "Purpose",
    dataIndex: "purpose",
    key: "age",
  },
  {
    title: "Data",
    dataIndex: "data",
    key: "data",
  },
];
const data = [
  {
    key: "1",
    activity: "Your Membership",
    purpose: (
      <div>
        <div>To register and process your application to be a Member</div>
        <div>
          To manage our relationship with you, which will include:<br></br>
          Processing and delivering your points or rewards, exchange your points
          for <br></br>vouchers/coupons and entering you in for prize
          draws/sweepstakes, including sharing your <br></br>personal data with
          trusted third-party vendors to provide you with incentives on our
          behalf;<br></br>
          Notifying you about changes to our terms or privacy policy;<br></br>
          Asking you to leave a review or inviting you to take a survey; or
          <br></br>
          For any other reason connected with your Membership.
        </div>
      </div>
    ),
    data: (
      <div>
        <div>(a) Identity data </div>
        <div>(b) Contact</div>
        <div>(c) Demographic/Profile</div> <div>(e) Technical Data</div>
        <div> (f) Pseudonymised data </div>
        <div>(panellist ID)</div>
      </div>
    ),
  },
  {
    key: "2",
    activity: "Market research surveys",
    purpose: (
      <div>
        We will ask you to participate in and provide feedback via surveys,
        including you telling us <br></br>about your preferences and consumer
        needs. This may also include analysing your behaviour<br></br> in
        different ways and for different purposes, but only provided such uses
        are for market research.<br></br>
        We may with your consent via a survey ask you to disclose certain
        Special Categories of <br></br>Personal data,but only if relevant to
        that survey and as may be allowed under applicable law.
      </div>
    ),
    data: (
      <div>
        (a) Identity<br></br>
        (b) Contact<br></br>
        (c) Special Categories of Personal Data.<br></br>
        (d) Demographic/Profile<br></br>
        (e) Technical data<br></br>
        (f) Pseudonmyised data (panellist ID)
      </div>
    ),
  },
  {
    key: "3",
    activity: (
      <div>
        Scientific research/Safety monitoring<br></br> (Pharmacovigilance
        Adverse Events Reporting)
      </div>
    ),
    purpose: (
      <div>
        We may ask you to participate in surveys for clients who are from a
        variety of types of<br></br> organisations such as; public health
        organisations, commercial or charity organisations or<br></br> academics
        from universities, etc and may be conducted using a variety of methods.
      </div>
    ),
    data: (
      <div>
        (a) Identity<br></br>
        (b) Contact<br></br>
        (c) Special Categories of<br></br> personal data (health,<br></br> e.g.
        disease, condition,<br></br> treatment, consumer<br></br> products and
        adverse events).<br></br>
        (d) Demographic/Profile
      </div>
    ),
  },
  {
    key: "4",
    activity: "Mobile Device surveys and usage",
    purpose: (
      <div>
        We use a mobile app, which may in the future with your permission,
        include location-based <br></br>geo fencing. If you agree to download
        and use our mobile application on your mobile phone,<br></br> tablet or
        pc (device), we collect the following information:<br></br>
        for administering the site, tracking users’ movements around the site
        and the Internet. We <br></br>use mobile analytics software to allow us
        to better understand the functionality of our<br></br> mobile app on
        your device. This software may record information such as how often you
        use <br></br>the app, the events that occur within the app, aggregated
        usage, performance data, and<br></br> where the app was downloaded from;
        <br></br>
        for quality checking, fraud or other legal reasons we refer to in this
        privacy notice;<br></br>
        Technical data and Demographic/Profile data are collected when you sign
        up to use our<br></br> Mobile app and via the surveys you undertake. We
        use this data for data matching and <br></br>enriching purposes and in
        ad-effectiveness campaigns for our clients;<br></br>
        to gather demographic information about our user base as a whole,
        including for example,<br></br> more generally for analysing trends of
        marketing research studies. Our clients may combine<br></br> those
        information with those of others to produce “aggregated” reports. They
        may also<br></br> create scientific reports based upon modelled
        information. “Modelled information” is data <br></br>developed based
        upon demographic and behavioural characteristics (like your <br></br>
        gender, age, and preferences) to predict what people with similar or
        matching characteristics<br></br> would watch or buy. We may receive
        reports based on the use of these technologies by<br></br> these
        companies on an individual as well as aggregated basis.
      </div>
    ),
    data: (
      <div>
        (d) Demographic/Profile data<br></br>
        (e) Technical data (collected<br></br> automatically - system and{" "}
        <br></br>performance information on the type<br></br> of device you use,
        operating system <br></br>version, device identifier and hardware{" "}
        <br></br>usage statistics such as CPU and app <br></br>version and
        Geo-location)<br></br>
        (f) Pseudonymised data
      </div>
    ),
  },
  {
    key: "5",
    activity: "Profiling",
    purpose: (
      <div>
        We may use your Demographic/Profile data for profiling purposes and (if
        you previously gave <br></br>us your ethnicity/racial profile), we may
        also use those information for profiling purposes,<br></br> provided
        this is permitted under local law. This means we will match you with
        appropriate <br></br>surveys to see if you qualify for particular
        surveys. Your birth date is automatically updated <br></br>so that we
        may ensure we select individuals, based on their age as may be required
        for the<br></br> relevant survey.
      </div>
    ),
    data: (
      <div>
        (c) Special Categories of personal data<br></br> (Ethnicity/Racial
        profile)<br></br>
        (d) Demographic/Profile
      </div>
    ),
  },
  {
    key: "6",
    activity: "Data matching and enrichment",
    purpose: (
      <div>
        We may from time to time share some of your personal data with select
        and trusted third<br></br> party processors. Those parties may append
        analytics or demographics data they previously<br></br> collected about
        you, which may be public sources (e.g. property ownership) and/or or
        private <br></br>sources (e.g. subscriber lists or retail purchases,
        with whom you have an account). We will<br></br> only share your
        Identity and Contact data with your prior consent, which may be as part
        of a <br></br>survey, or an invitation to participate in such programs.
        <br></br>
        We may also use your Pseudonymised data and/or Demographic/Profile data
        to add to<br></br> existing anonymous data sets or create new ones.
        <br></br>
        We use them so we can enrich the data we hold about you to improve your
        panel profile, your<br></br> panel Membership experience and to ensure
        you are selected for relevant surveys.
      </div>
    ),
    data: (
      <div>
        (a) Identity<br></br>
        (b) Contact<br></br>
        (d) Demographic/Profile data<br></br>
        (e) Technical data (e.g. IP address, ADID’s, browser and device{" "}
        <br></br>information (incl. Mobile Device ID’s, App Technical data,
        usage,<br></br> performance, data and location data))<br></br>
        (f) Pseudonymised data
      </div>
    ),
  },
  {
    key: "7",
    activity: "Ad-Effectiveness campaigns & look-a-likes",
    purpose: (
      <div>
        To measure ad-effectiveness and/or to create ‘look-a-like’ groups that
        have common <br></br>Demographics/Profile data (or interests). Our
        clients then find people who are similar to<br></br> those groups and
        use that information in a way that reaches new potential consumers and
        so<br></br> helps them improve their advertising targeting and their
        online advertising models.<br></br>
        We may share your unique panellist ID (UID) with our clients, who may
        write, set or read<br></br> cookies or use other Technical data about
        you from multiple locations, including our own<br></br> servers or
        systems. If you participate, your UID will be stored or associated with
        those <br></br>Technical data to allow us and/or our clients to conduct
        online ad-effectiveness campaigns,<br></br> track your visits to
        websites, use your Demographic/Profile data to create look-a-like
        <br></br> segments and/or gain other online insights about you. If you
        have interacted with the online<br></br> ad or promotion, our client
        will send your UID and the specific survey to us and we may<br></br>{" "}
        provide you with the opportunity to complete the survey. We will not
        transfer any Identity or<br></br> Contact data to our clients unless we
        have first received your consent and no third parties<br></br> may
        contact you or use your data for any other purpose.
      </div>
    ),
    data: (
      <div>
        (b) Contact data (email address)<br></br>
        (d) Demographic/Profile data<br></br>
        (e) Technical data (UID, setting cookies)<br></br>
        (f) Pseudonymised data
      </div>
    ),
  },
  {
    key: "8",
    activity: "Ad exposure and measurement",
    purpose: (
      <div>
        We may match your personal data against our clients or trusted partners
        information <br></br>to determine if you use any of their products or
        services and/or if you have been exposed to any <br></br>of their
        advertisements. This helps our clients understand more about consumers
        behaviour<br></br> when ads are displayed to them.
      </div>
    ),
    data: (
      <div>
        (a) Identity (UID)<br></br>
        (b) Contact (email address)<br></br>
        (d) Demographic/Profile<br></br>
        (e) Technical data<br></br>
        (f) Pseudonmyised data<br></br>
      </div>
    ),
  },
  {
    key: "9",
    activity: "Legal/public authority disclosure",
    purpose: (
      <div>
        We will not transfer any Identity or Contact data to third parties
        unless we have first received<br></br> your consent and no third parties
        may contact you or use your data for any other purpose.<br></br>
        Though we make every effort to preserve your privacy, we may be required
        to disclose your<br></br> personal data in response to lawful requests
        by public authorities, including to meet national<br></br> security or
        law enforcement requirements; or to comply with a subpoena or other
        legal<br></br> process, when we believe in good faith that disclosure is
        necessary to protect our rights, to <br></br>protect your safety or the
        safety of others, to investigate fraud or breaches of our site terms,{" "}
        <br></br>or to respond to a government request.
      </div>
    ),
    data: (
      <div>
        (a) Identity<br></br>
        (b) Contact<br></br>
        (d) Demographic/Profile<br></br>
        (e) Technical data<br></br>
        (f) Pseudonmyised data
      </div>
    ),
  },
  {
    key: "10",
    activity: "Fraudulent and quality checking",
    purpose: (
      <div>
        We use multiple Technology data for quality control, validation, and
        fraud detection<br></br> and prevention purposes, including assisting us
        in ensuring the integrity of survey results. Please<br></br> see the
        section entitled ‘What other tracking technologies do we use for surveys
        you<br></br> participate in and for other purposes?’ for more on this.
      </div>
    ),
    data: (
      <div>
        <div>(e) Technical data</div>
        <div>(f) Pseudonymised data</div>
      </div>
    ),
  },
];

export default function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <div className="container py-5">

   
      <Typography>
        <Title>Privacy Policy</Title>
        <Title level={2}>Who are we? </Title>
        <Paragraph>
          <Text strong>
            {" "}
            We’re Websamp (“We” “Our” “Us” or “Websamp”). We’re a part of the
            Continuum Insights.
          </Text>
          <br></br>
          Websamp is an online market research company that champions market
          research and technology. When you sign up on our tool, you can be rest
          assured of your data security. All the survey responses are completely
          anonymous and no personal information is shared with our clients.
          <br></br>
          Websamp with whom you registered with as a member is the controller
          and responsible for your personal data. We care our data protection
          and have dedicated team that is responsible for overseeing questions
          in relation to this privacy notice. If you have any questions about
          this privacy notice, including any requests to exercise your legal
          rights, you can fill our contact form on the contact page. This
          privacy notice does not apply where you have agreed to participate in
          surveys administered by third parties. In these situations, Websamp is
          not the sponsor of the survey and the technologies/media of those
          surveys and the survey results or survey data are and will be
          controlled by the sponsor of those surveys. Please refer to the
          relevant sponsor’s privacy policy to learn more about those sponsor’s
          privacy practices.
        </Paragraph>

        <Title level={2}>Our Privacy Commitment</Title>
        <Paragraph>
          Personal Information is key and we value you sharing that with us and
          we honour that trust. This privacy notice will guide you in detail
          about our processes of how we collect, use, share and secure personal
          data you provide on our website, when you sing up to become our member
          and participate in surveys and/or research studies ("surveys”) that we
          conduct for, and on behalf of our clients. This section will also
          explain your privacy rights and how laws that are applicable to you
          may protect you and is intended to supplement other notices and
          privacy policies and not to override them. The sign up, use of and
          access to your membership and use of our technology and tools
          (“Services”) is subject to this privacy notice. Additionally, this
          privacy notice is provided in stacked format, so that you access
          different sections and get more details. We also request you to refer
          to our Glossary section to understand the meaning of unfamiliar terms
          used in this privacy notice.
        </Paragraph>
        <Title level={2}>FAQs</Title>

        <Title level={4}>WHAT PERSONAL DATA DO WE COLLECT ABOUT YOU?</Title>
        <Paragraph>
          We define personal data as the information provided by you from which
          you can be identified. We don’t include data where the identity has
          been removed (i.e. anonymous data). Depending upon on the requirement
          of the survey, we may collect, use, store and transfer various kinds
          of personal data responding to you which can be grouped together as
          mentioned below -<Text strong>Identify Data</Text> - Names, Date of
          Birth, Marital Status, Gender, Panelist Id and Username. <br />
          <Text strong>Contact Data </Text>- Email Address and Mobile Number.{" "}
          <br />
          <Text strong>Special categories of personal data</Text> –
          Ethnic/Racial origin, Health, Genetics, Political opinion, religion,
          sexual orientation and sex life.
          <br />
          <Text strong>Demographic/Profile data</Text> – interests, preferences,
          feedback and survey responses and including, but not limited to; age,
          marital status, gender, birthday, household size, income, education
          and employment status.
          <br />
          1.Technical data includes internet protocol (IP) addresses, login
          data, browser type and version, time zone setting and location,
          browser plug-in types and versions, operating system and platform and
          other technology on the devices you use to access this website.
          <br />
          2.Pseudonymised data are identifiable data as unique identifiers e.g.
          panellist ID’s or Technical data are used, however direct identifiers,
          such as; name and Contact data are removed.
        </Paragraph>
        <Title level={4}>Member Info</Title>
        <Paragraph>
          When you sign up to our website (“Member”), you will be able to
          participate in the our surveys. You would be required to complete
          registration via our website.
        </Paragraph>
        <Title level={4}>Non Member Info</Title>
        <Paragraph>
          In a situation that you’re not registered (“Non Member”), but you
          intend to take the surveys, you would need to provide your details. We
          will be only preserving your email address, gender, age information,
          machine ID and IP address. We do this so we may be able to contact you
          for your rewards. to match you against the survey you are taking; and
          improve the user experience. Under no other circumstances, we will
          contact you.
        </Paragraph>
        <Title level={4}>Public Forums and Blogs</Title>
        <Paragraph>
          Our website believes in transparency and we provide public access to
          our blogs and community forums. We’d like to keep you aware that any
          information that you may provide in the public sections may be read,
          collected and used by others who would also have access to them.
          <br />
          As part of our website experience, we love to highlight testimonials
          of satisfied members on our website. The same will be done with your
          consent and will be only posted once you give us a go ahead. The same
          can be update and or delete upon your request through our contact
          section. Personal data you choose to make public on different sections
          of our website, in community forums can be read, collected, stored
          and/or used by other users and could be used to send you unsolicited
          messages.
          <br />
          As a part of our effort to keep your data secure,we strongly
          discourage you from disclosing any of your personal data in any of
          public forums and community sections. We won’t be able to guarantee
          that third parties with whom you share your personal data via
          community forums will be kept secure and confidential. Please be aware
          that we are not responsible for the personal data you choose to submit
          or make public. In addition, we are not responsible for the content
          you publicly post on the site that can be found via web-based search
          engines.
        </Paragraph>
        <Title level={5}>
          WHAT PERSONAL DATA DO WE COLLECT ABOUT YOU AND HOW DO WE USE YOUR
          PERSONAL DATA?{" "}
        </Title>
        <Paragraph>
          Personal Data is explained in the table below in detail. Be rest
          assured that none of your personal data will be used for any kind of
          modelling, analytical or data matching purposes, other than the
          reasons we state in the table. When there are third parties involved,
          we ensure that your data is kept secure, confidential and erased in
          accordance with our data retention and destruction policies as set out
          in our Panellist Privacy Notice. We follow the rules and legal
          guidelines set by the law making authorities as per the operating
          countries pertaining to personal data usage. These legal bases are
          listed as follows and we may use more than one lawful basis when
          processing your personal data. Consent – In certain cases, we collect
          and process your personal data with your consent e.g. when you
          participate in surveys, we will ask you if you wish to participate.
          Contractual obligations – In some circumstances, we need to process
          your personal data to comply with a contractual obligation e.g. when
          we use your personal data to send you your rewards. Legal compliance –
          If the law requires us to, we may need to collect and process your
          personal data in response to lawful requests by public authorities or
          if e.g. we believe in good faith that disclosure is necessary to
          protect our rights, to protect your safety or the safety of others, to
          investigate fraud or breaches of our site terms, or to respond to a
          government request. Legitimate interest – means the interest of our
          business in conducting and managing our business to enable us to give
          you the best service/product and the best and most secure experience.
          We make sure we consider and balance any potential impact on you (both
          positive and negative) and your rights before we process your personal
          data for our legitimate interests. We do not use your personal data
          for activities where our interests are overridden by the impact on you
          (unless we have your consent or are otherwise required or permitted to
          by law).
        </Paragraph>

        <Table dataSource={data} columns={columns} />
        <Title level={5}>Do we share your personal data with anyone? </Title>
        <Paragraph>
          As part of our offices across the country, our team members may access
          or use your personal data for the purposes set out in table above
          and/or to carry out our regular business activities, such as to
          provide, maintain and personalise our sites and services, to
          communicate with you, and to accomplish our legitimate business
          purposes, pursuant to contractual safeguards.
          <br />
          As a standard procedure, we collect data in the form of inputs from
          our surveys that are provided to our clients either in anonymised and
          aggregated form or in pseudonymised form. In case the results in
          anonymised and aggregated form, include information about groups of
          individuals and not on an individual level . If a scenario comes up
          where the the results in pseudonymised form, the results will be on an
          individual basis, using unique identifiers, such as your assigned
          panellist ID, but will not include your Identity data or Contact data.
          <br />
          We only provide contact or identity data to our clients in specific
          surveys for the purposes of research only. As an organization, we
          would never provide such data to our clients unless we have first
          received your consent and confirmed with them that their use is in
          accordance with applicable law and market research codes of practice.
          <br />
          From time to time we may engage third parties to issue you with
          cheques or processing the delivery of your rewards and /or other third
          parties for the purposes of those parties providing us or our clients
          with services such as; data processing or analytics services or to
          append data they previously collected about you. Such third parties
          are not allowed to use your personal data for any other reason and we
          enter into contracts with those third parties to ensure your personal
          data is kept secure and erased in accordance with our data retention
          and destruction policies.
          <br />
          At times, Websamp may use third-party software for email list
          management and email distribution omay use third party list
          management. These companies are thoroughly checked and authorized to
          use your personal data only as necessary to provide these services to
          us, pursuant to written instructions. In such cases, these companies
          must abide by our data privacy and security requirements and are not
          allowed to use personal data they receive from us for any other
          purpose.
          <br />
          We make a concerted effort to preserve your privacy, we may be
          required to disclose your personal data in response to lawful requests
          by public authorities, including to meet national security or law
          enforcement requirements.
          <br />
          Additionally, we may also disclose your personal information as
          required by law, such as to comply with a subpoena or other legal
          process, when we believe in good faith that disclosure is necessary to
          protect our rights, to protect your safety or the safety of others, to
          investigate fraud or breaches of our site terms, or to respond to a
          government request.
          <br />
          Please see the table above for more on these uses.
        </Paragraph>
        <Title level={4}>
          Does Websamp transfer your personal data to other countries?
        </Title>
        <Paragraph>
          Websamp is an India first company and your personal data will only be
          transferred across our offices and our trusted service providers. We
          follow all the data privacy laws as applicable in India.
        </Paragraph>
        <Title level={4}>
          Are there any alternate ways of becoming a member?
        </Title>
        <Title level={5}>Mobile Applications</Title>
        <Paragraph>
          You can become a Member by downloading our mobile application (app).
          If you download the mobile app, we can automatically collect system
          and performance information on the type of device you use, operating
          system version, device identifier and hardware usage statistics such
          as CPU, RAM, storage, and app version. We will use the information we
          collect via our mobile app in accordance with the terms of this
          privacy notice and the provisions set out below.<br></br>
          Additionally, based on your preferences, we can send you push
          notifications from time-to-time in order to notify you about surveys
          and ask you if you would like to participate in certain marketing
          research studies in accordance with this privacy notice. You can opt
          out of the notifications by turning them off at the device level. To
          ensure you receive proper notifications, we will need to collect
          certain information about your device such as operating system and
          user identification information.
        </Paragraph>
        <Title level={5}>SMS</Title>
        <Paragraph>
          In certain cases, you can enable the services using SMSs, either
          directly or through third parties by which you can receive messages on
          your wireless device via short message service ("SMS Service")
          Standard provider data and messaging rates apply to all SMS
          correspondence. All charges are billed by and payable to your mobile
          service provider. You represent that you are the owner or authorised
          user of the wireless device on which messages will be received and
          that you are authorised to approve the applicable charges. Personal
          data obtained from you in connection with this SMS Service may include
          your Identity data and Contact data, your provider's name, and the
          date, time, and content of your messages. The use of this information
          will be in accordance with this privacy notice. If fees are charged to
          your wireless account invoice, we may be required to provide your
          carrier with your Identity data and Contact data in connection
          therewith. Your wireless carrier and other service providers may also
          collect data about your wireless device usage, and their practices are
          governed by their own policies. You acknowledge and agree that the SMS
          Service is provided via wireless systems which use radios (and other
          means) to transmit communications over complex networks. We will not
          be liable for any delays in the receipt of any SMS messages, as
          delivery is subject to effective transmission from your network
          operator. SMS message services are provided on an as-is basis. We do
          not guarantee that your use of the SMS Service will be private or
          secure, and we are not liable to you for any lack of privacy or
          security you may experience. You are fully responsible for taking
          precautions and providing security measures best suited for your
          situation and intended use of the SMS Service. You can opt out from
          this service at any time by going to your account page and unticking
          the option “I agree to receive invitations to surveys by SMS” in the
          Personal Details tab. If available in your country, you may also be
          able opt out by text by following the instructions given to you in the
          SMS we send you.
        </Paragraph>
        <Title level={5}>Single Sign-ON</Title>
        <Paragraph>
          As a websamp member, you can log in to our portal using sign-in
          services such as Facebook Connect or an Open ID provider. When you
          sign up using any of the aforementioned services, it will authenticate
          your identity and provide you with the option of becoming a Member.
          Services like Facebook Connect give you the option to post information
          about your activities on this site to your profile page to share with
          others within your network.
        </Paragraph>
        <Title level={5}>Referral</Title>
        <Paragraph>
          We encourage our members to onboard their peers and enjoy referral
          rewards. If you choose to use our referral service to tell a friend
          about our site, you may pass your unique referral link to your friend
          and if your friend becomes a Member, you will win rewards.
        </Paragraph>
        <Title level={4}>What is Websamp’s cookie policy? </Title>
        <Paragraph>
          We use cookies to distinguish you from other users of our site, and
          estimates visits and click throughs for specific campaigns. At
          Websamp, this enables us to provide you with a good experience when
          you browse our site and also allows us to improve our site. Cookies we
          use expire when you end your browsing session. By continuing to
          explore our website, you are agreeing to our use of cookies. We define
          a cookie as a small file of letters and numbers that we store on your
          browser or the hard drive of your computer if you agree. Cookies
          contain information that is transferred to your computer's hard drive.
          For detailed information on the cookies we use and the purposes for
          which we use them, see our cookie policy at:
        </Paragraph>
        <Title level={4}>
          Are there any other tracking technologies Websamp deploys for surveys
          you participate in and for other purposes?
        </Title>
        <Paragraph>
          For Surveys, we deploy client cookie tracking, which also includes
          cookies for Ad effectiveness campaigns As a part our standard
          operating procedure, we may share your unique panelist ID (UID) with
          our clients, who may write, set or read cookies or use other Technical
          data about you from multiple locations, including our own servers or
          systems. Your participation in surveys is accounted for an agreement
          in storing of your UID or associated with those Technical data to
          allow us and/or our clients to conduct online ad-effectiveness
          campaigns, track your visits to websites, use your Demographic/Profile
          data to create look-a-like segments and/or gain other online insights
          about you. In case of your interaction with the online ad or
          promotion, our client will send your UID and the specific survey to us
          and we may provide you with the opportunity to complete the survey.
        </Paragraph>
        <Title level={4}>Other Technologies</Title>
        <Title level={5}>Automated technologies or interactions.</Title>
        <Paragraph>
          Post becoming a member, when you interact with our website, Your
          Technical data will be automatically collected which would entail your
          equipment, browsing actions and patterns. The way we collect this
          technical data is using cookies, server logs, digital finger printing
          technologies and other similar technologies. Also, Websamp may receive
          your technical data from other websites employing our cookies. Please
          see our cookie policy for further details.
        </Paragraph>
        <Title level={5}>Server log files:</Title>
        <Paragraph>
          Websamp may additionally collect Internet protocol (IP) addresses,
          browser type, internet service provider (ISP), referring/exit pages,
          operating system, date/time stamp, and/or clickstream data. We may
          combine this Technical data with other information collected about you
          for the purposes of improving our services, site functionality and
          collecting analytical data.
        </Paragraph>
        <Title level={5}>Fingerprinting technologies:</Title>
        <Paragraph>
          At Websamp, digital Fingerprinting technologies assign a unique
          identifier or "Machine-ID" to a user's computer to identify and track
          your device (“Technology"). The Technology will analyse publicly
          available information and data obtained from your computer's web
          browser and from other publicly available data points, including the
          technical settings of your computer, the characteristics of your
          computer, and its IP Address, to create a unique identifier, which
          will be assigned to your device. For us, this technology is put to use
          for quality control, validation, and fraud detection and prevention
          purposes, including assisting us in ensuring the integrity of survey
          results. We follow appropriate technical and operational processes and
          procedures will be put in place to ensure that the Technology is safe,
          secure and does not cause undue privacy or data security risks and the
          Technology will be used and distributed in a professional and ethical
          manner and in accordance with (a) this privacy notice, (b) any other
          statements and/or disclosures made to you as a member of the community
          and (c) applicable laws and market research codes of practice. At any
          given point, if we spot an event where any unethical conduct is
          discovered in connection with the use of the Technology, or that the
          Technology is being used in a manner that is inconsistent with privacy
          notice, immediate action will be taken to prohibit such unethical
          conduct and to ensure the proper administration of the Technology.
        </Paragraph>
        <Title level={5}>Advertising</Title>
        <Paragraph>
          Websamp partners with multiple third parties to either display
          advertising on our site or to manage our advertising on other sites.
          Our third-party partners may use technologies such as cookies to
          gather information about your activities on their site and other sites
          in order to provide you advertising based upon your browsing
          activities and interests.
        </Paragraph>
        <Title level={5}>Digital Fingerprinting</Title>
        <Paragraph>
          At Websamp, digital Fingerprinting technologies assign a unique
          identifier or "Machine-ID" to a user's computer to identify and track
          your computer. We will not use digital fingerprinting technology (the
          “Technology") to collect your Identity or Contact data, or track your
          online activities; and will not disrupt or interfere with the use or
          control of your computer or alter, modify or change its settings or
          functionality. In certain cases, in specific market research programs,
          we will use the Technology to assist our clients in ensuring the
          integrity of survey results. The Technology will analyse publicly
          available information and data obtained from your computer's web
          browser and from other publicly available data points, including the
          technical settings of your computer, the characteristics of your
          computer, and its IP Address, to create a unique identifier, which
          will be assigned to your computer. The unique identifier will be an
          alpha-numeric ID In order to assist our clients in protecting and
          ensuring the integrity of survey results, we may; (a) link or
          associate your unique identifier to you and any of your personal data;
          (b) share your unique identifier with our clients and with other
          sample or panel providers; and (c) receive or obtain a unique
          identifier linked to you from a third party, including without
          limitation a sample or panel provider or our client, however we will
          only provide such information to any third parties (including our
          clients) on an aggregated and anonymised or pseudonymised basis. In
          addition, any unique identifier(s) received or obtained by us and
          linked to you will be protected in accordance with this privacy notice
          and if required by law, we will ask for your consent in advance. As
          Websamp, we do everything we can to ensure that the Technology is
          safe, secure and does not cause undue privacy or data security risks
          and we shall use and distribute the Technology in a professional and
          ethical manner and in accordance with (a) this privacy notice, (b) any
          other statements and/or disclosures made by us to you and (c)
          applicable laws and market research codes of practice. At any given
          point, if we spot an event where any unethical conduct is discovered
          in connection with the use of the Technology, or that the Technology
          is being used in a manner that is inconsistent with privacy notice,
          immediate action will be taken to prohibit such unethical conduct and
          to ensure the proper administration of the Technology.
        </Paragraph>
        <Title level={5}>Social Media Widgets</Title>
        <Paragraph>
          As an industry wide best practice, Websamp’s website includes Social
          Media Features, such as the Facebook Like button (and Widgets, such as
          the Share This Button or interactive mini-programs that run on our
          site). These Features may collect your IP address; which page you are
          visiting on our site; and may set a cookie to enable the Feature to
          function properly. Social Media Features and Widgets are either hosted
          by a third party or hosted directly on our site. Your interactions
          with these Features are governed by the privacy notice of the company
          providing it.
        </Paragraph>

        <Title level={4}>
          Does Websamp have a winner’s policy and privacy guarantee?
        </Title>
        <Paragraph>
          All Websamp registered members and others who take our surveys are
          randomly selected to win prizes for participation in surveys, content,
          games and other promotions. We will be notifying all the winners by
          email or via our site of their winnings. Websamp shall post the
          usernames of winners on our site and on our own blogs and newsletters.
          For more information, please visit our Terms and Conditions page. Cash
          and other prizes awarded to non-members will be awarded directly.
        </Paragraph>
        <Title level={4}>
          What are some of the security measure taken by Websamp to to protect
          your personal data?
        </Title>
        <Paragraph>
          We are very concerned when it comes to your personal data. We’ve put
          in place appropriate security measures to prevent your personal data
          from being accidentally lost, used or accessed in an unauthorised way,
          altered or disclosed. Additionally, as an organisation, we limit
          access to your personal data to those employees, agents, contractors
          and other third parties who have a business need to know. They will
          only process your personal data on our instructions, and they are
          subject to a duty of confidentiality. We do do everything we can to
          keep your data safe, however in some cases, unfortunately no systems
          can guarantee they are 100% secure. If you have questions about the
          security of your personal data, or if you have reason to believe that
          the personal data that we hold about you is no longer secure, please
          contact us immediately as described in this Privacy Notice. We have
          put in place procedures to deal with any suspected personal data
          breach and will notify you and any applicable regulator or supervisory
          authority of a breach where we are legally required to do so.
        </Paragraph>
        <Title level={4}>
          What’s Websamp’s data retention and destruction policies?
        </Title>

        <Title level={5}> How long will you use my personal data for? </Title>
        <Paragraph>
          Your data is a matter of personal responsibility for Websamp. At any
          given time, we will only retain your personal data for as long as
          necessary to fulfill the purposes we collected it for, including for
          the purposes of satisfying any legal, accounting, or reporting
          requirements. In order to determine the appropriate retention period
          for personal data, we consider the amount, nature, and sensitivity of
          the personal data, the potential risk of harm from unauthorised use or
          disclosure of your personal data, the purposes for which we process
          your personal data and whether we can achieve those purposes through
          other means, and the applicable legal requirements.As the results of
          the surveys and other aggregated or Pseudonymised data are used for
          research, scientific, historical and/or statistical purposes (whether
          publicly or privately funded), we and our clients or other third
          parties may use this information for such purposes in accordance with
          the terms indefinitely without further notice to you.
        </Paragraph>
        <Title level={5}>Business Transfers</Title>
        <Paragraph>
          Websamp is an organisation focused on delivering the best for our
          clients and consumers. As we continue to develop our business, there
          might be scenarios where we might sell or buy additional assets. In
          such transactions, our right to contact you as a Member and the
          information you have voluntarily provided in your user profile would
          generally be one of the transferred business assets. Any merger, sale
          or transfer of Websamp of substantially or all of its business assets
          (which included our influencer panel), or other fundamental corporate
          transaction would be to an entity that agreed to comply with all of
          the material terms of our privacy notice. We will notify you via email
          and/or a prominent notice will be posted on our site if any
          transaction may result in any new proposed uses of your personal data
          which are incompatible with the uses set out in this Privacy Notice
          and of the choices you may have regarding your personal data.
        </Paragraph>
        <Title level={5}>Links</Title>
        <Paragraph>
          Websamp may offer visitors the ability to voluntarily link to other
          sites from time to time. We don’t review, and are not responsible for,
          the content or effect of the privacy policies of these sites.
        </Paragraph>
        <Title level={5}>Children’s Privacy</Title>
        <Paragraph>
          Websamp under no circumstance will not knowingly collect any
          information from any child under the age of 18 without permission from
          his/her parents, legal guardian or an adult responsible for the care
          of the child. Eligible ‘young people’ (individuals aged between 18 and
          20) may be invited to participate in our Teens panel from time to
          time. We comply with the local authorities for the same. If you are a
          parent or guardian of a child under 18 and become aware that your
          child has provided personal data to us and you are not aware of such
          processing, please contact us as described in this Privacy Notice and
          we will take reasonable steps immediately to remove any such
          information.
        </Paragraph>
        <Title level={4}>Your Rights</Title>
        <Title level={5}>
          How do I access my information; use the member services area and/or
          update, correct or delete your information?
        </Title>
        <Paragraph>
          Based on individually made requests, Websamp will adhere and provide
          you with information about whether we hold any of your personal data.
          This will allow you to access, correct, or request deletion of your
          personal data, or terminate your membership by logging into your
          Member Account. We recommend following the appropriate directions,
          your information should be automatically updated in our database. For
          these purposes, and if you are unable to correct your personal data
          yourself via the Account, you may write to us at the postal address
          found at the end of this privacy notice, or contact us here{" "}
          <a
            href="vijay.rajput@continuuminsights.com"
            style={{ textDecoration: "none", color: "#007BFF" }}
          >
            vijay.rajput@continuuminsights.com
          </a>
          . We will respond to all requests within a reasonable timeframe.
        </Paragraph>
        <Title level={5}>
          How do I terminate my influencers panel membership?
        </Title>
        <Paragraph>
          In a likely scenario that you decide to end your membership with us or
          to require us to cease processing your personal data, you may
          discontinue your membership by going to “Account” and selecting
          “Unsubscribe Options” on the bottom left-hand corner, then "Cancel
          your account". We recommend following the appropriate directions, as
          that would enable you to have your record marked as "do not contact",
          and you will no longer receive communications. In addition, you will
          forfeit any incentive balance that has not been requested as of the
          time you opt out. As an alternative, you may send an email directly
          using the Contact us form requesting to be removed. Email links are
          provided on the site so that you may contact us directly with any
          questions or concerns you may have. Each email we receive is read and
          responded to individually. In most cases it will take 2 to 3 days to
          process this change, but please allow up to two full weeks for your
          status to be finalised. Please note that you may continue to receive
          communications during this short period after changing your
          preferences while our systems are fully updated.
        </Paragraph>
        <Title level={5}>Data Retention</Title>
        <Paragraph>
          As a websamp member, If you terminate your membership, Websamp will no
          longer use your personal data to contact you, but in accordance with
          our backup procedures, we will retain your personal data until your
          personal data are eventually destroyed in accordance with our data
          retention and destruction policies and we will continue to employ the
          security procedures and technologies to keep your personal data safe.
        </Paragraph>
        <Title level={5}>How can I ask a question or make a complaint?</Title>
        <Paragraph>
          We believe in feedback and take pride in providing the best services
          to our members and clients. If you have a question or complaint about
          the use or disclosure of your personal data to our Privacy Contact. We
          will investigate and attempt to resolve any complaints or disputes
          regarding the use or disclosure of your personal data within 30 days
          of receiving your complaint.
        </Paragraph>
        <Title level={4}>Your other rights</Title>

        <Title level={5}>You may:</Title>
        <Paragraph>
          As member request access to your personal data and we may conduct ID
          checks before we can respond to your request. As a member, have your
          personal data erased, corrected or restricted if it is inaccurate or
          requires updating. You may also have the right under certain
          circumstances to request deletion of your personal data; however, this
          is not always possible due to legal requirements and other obligations
          and factors. You can update your account information via your Account
          or by contacting us at the address given below. object to the
          processing your personal data if we are not using your personal data
          for the purposes set out in this privacy policy.
        </Paragraph>
        <Title level={5}>
          Changes to the privacy notice and your duty to inform us of changes
        </Title>
        <Paragraph>
          This version was last updated on the date at the top of this privacy
          policy. As websamp, we reserve the right to modify this privacy
          statement at any time, so please review it frequently. If we do decide
          to change our privacy notice, we will post those changes to this
          privacy statement on the homepage, or other places we deem appropriate
          so that you are aware of what information we collect, how we use it,
          and under what circumstances, if any, we disclose it. If we make
          material changes to this policy, we will notify you here, by email, or
          by means of a notice on our home page prior to the change becoming
          effective. It is important that the personal data we hold about you is
          accurate and current. Please keep your Account details updated if your
          personal data changes during your relationship with us.
        </Paragraph>

        <Title level={5}>Data Protection Officer</Title>
        <Paragraph>
          Websamp has appointed a Data Protection Officer (DPO) who is
          responsible for overseeing questions in relation to this privacy
          policy. If you have any questions about our privacy practices,
          including how we handle your personal information, or if you want to
          exercise any of your rights under applicable data protection laws, you
          can contact our Data Protection Officer using the following contact
          details:
          <br></br>
          <br></br>
          Name of Data Protection Officer: Vijay Rajput
          <br></br>
          Email:{" "}
          <a
            href="vijay.rajput@continuuminsights.com"
            style={{ textDecoration: "none", color: "#007BFF" }}
          >
            {" "}
            vijay.rajput@continuuminsights.com{" "}
          </a>
          <br></br>
          Phone: +919717866086
          <br></br>
          <br></br>
          Please include "Privacy Policy" in the subject line of your email or
          letter and clearly state your request or concern. We will do our best
          to address your inquiry in a timely manner.
        </Paragraph>
      </Typography>
      </div> 
    </>
  );
}
