import React, { useState, useEffect } from "react";
import { NavLink, Outlet } from "react-router-dom";
import Sidenav from "../dashboard/SideNav";
import DashbordBanner from "../dashboard/components/DashboardBanner";
import { useSelector } from "react-redux";
import ExtraInfo from "../dashboard/components/ExtraInfo";
import { Segmented } from "antd";

const DashboardLayout = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isOpen = useSelector((state) => state.sidebar.isOpen);

  return (
    <div>
      <Sidenav />
      <div className="main-content-wrapper">
        <div className={isOpen ? "left" : "left close-left"}></div>
        <div className={isOpen ? "right" : "right max"}>
          <DashbordBanner />
          <div className="page-content mt-4   px-md-5">
            <div className="dashboard-tabbar position-sticky fixed-top">
              <ul className="d-flex align-items-center justify-content-start">
                <li>
                  <NavLink to="/profile-survey">Profile Survey</NavLink>
                </li>
                <li>
                  <NavLink to="/survey"> Paid Survey</NavLink>
                </li>
                <li>
                  <NavLink to="/refer">Refer & Earn</NavLink>
                </li>
                <li><NavLink to="/earning">Earnings</NavLink></li>
                <li>
                  <NavLink to="/reward">Redeem</NavLink>
                </li>
              </ul>
            </div>
            <Outlet />
          </div>
          <div className="dashboard-footer py-3 mt-4">
            <p className="text-center mb-0">Websamp Tool © Continuum Insight</p>
          </div>
        </div>
      </div>
      <ExtraInfo />
    </div>
  );
};

export default DashboardLayout;
