import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserSurveyList } from "../redux/slice/surveySlice";
import "./dashboardStyle.css";
import AllSurveysList from "./components/AllSurveysList";
import DashboardProfileSurvey from "./components/DashboardProfile";

const DashbordHome = () => {
  const dispatch = useDispatch();
  const [surveys, setSurveys] = useState({ items: [] });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(getUserSurveyList({ order_by: ["-created_at"] }));
  }, [dispatch]);

  const assignedSurveys = useSelector(({ assignedSurveys }) => assignedSurveys);
  console.log("assignedSurveys", assignedSurveys);
  useEffect(() => {
    if (assignedSurveys.surveys) {
      setSurveys(assignedSurveys.surveys);
    }
  }, [assignedSurveys]);

  const limitedSurveys = {
    ...surveys,
    items:
      surveys.items && Array.isArray(surveys.items)
        ? surveys.items.slice(0, 5)
        : [],
  };

  return (
    <div>
      <div className="container min-h">
        <AllSurveysList
          surveys={limitedSurveys}
          fetching={assignedSurveys?.fetching}
        />
        <DashboardProfileSurvey />
      </div>
    </div>
  );
};

export default DashbordHome;
