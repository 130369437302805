import React, { useState } from "react";
import LinkedInOAuth from "./LinkdingLoginnew";
import CustomButton from "./customlogin";
import { NavLink } from "react-router-dom";
import {message} from "antd";


const LoginForm = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleCheckboxChange = (e) => {
    e.stopPropagation(); 
    setIsChecked(!isChecked);
    setErrorMessage("");
  };

  const handleCardClick = () => {
    console.log("clivk")
    if (!isChecked) {
      setErrorMessage("Please agree to the terms by checking the checkbox.");
    } else {
      setErrorMessage("");
    }
  };

  return (
    <div className="log-form" onClick={handleCardClick}>
      <div className="form-check d-flex align-items-center ps-0" >
        <input
          className="form-check-input ms-0"
          type="checkbox"
          id="flexCheckDefault"
          checked={isChecked}
          onChange={handleCheckboxChange}
          onClick={(e) => e.stopPropagation()} 
        />
        <label className="form-check-label ms-4" htmlFor="flexCheckDefault">
          I agree to the <NavLink to="/term-service">Terms</NavLink> of Use and
          to receive marketing email messages from Websamp, and I accept the{" "}
          <NavLink to="/privacy-policys">Privacy Policy</NavLink> .
        </label>
      </div>
      {errorMessage && (
        <div className="error-message text-danger mt-2">{errorMessage}</div>
      )}
      <div className="login-btns-wrapper mt-5">
        <CustomButton disabled={!isChecked} onClick={handleCardClick} />
        <LinkedInOAuth disabled={!isChecked} onClick={handleCardClick} />
      </div>
    </div>
  );
};

export default LoginForm;
