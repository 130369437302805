import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  Row,
  DatePicker,
  Space,
  Select,
} from "antd";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateMe } from "../../redux/slice/authSlice";
import moment from "moment";
import "./details.css";

const EditUserDrawer = ({ setIsEditableDrawerOpen, reloadUser }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { personalDetail } = useSelector(({ auth }) => auth);

  const onSaveSuccess = () => {
    setIsEditableDrawerOpen(false);
    reloadUser();
  };

  const disabledDate = (current) => {
    return current && current > moment().subtract(18, "years").endOf("day");
  };

  const onFinish = (value) => {
    if (value.dob) {
      value.dob = value.dob.format("DD MMM YY");
    }
    dispatch(updateMe(value)).then(() => {
      onSaveSuccess();
    });
  };

  useEffect(() => {
    if (Object.keys(personalDetail).length) {
      form.setFieldsValue({
        name: personalDetail?.name,
        mobile: personalDetail?.mobile,
        gender: personalDetail?.gender,
        dob: null,
      });
    }
  }, [personalDetail, form]);

  const onClose = () => {
    setIsEditableDrawerOpen(false);
  };

  return (
    <>
      <Drawer
        title="Edit Your Details"
        className="editUserDrawer"
        closable={false}
        onClose={onClose}
        open={true}
        placement="right"
        style={{ position: "fixed", right: 0 }}
        bodyStyle={{
          paddingBottom: 80,
        }}
        extra={
          <Space>
            <Button
              form="survey_submit_form"
              key="submit"
              htmlType="submit"
              type="primary"
            >
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </Space>
        }
      >
        <div className="drawer-content-container">
          <Form
            id="survey_submit_form"
            layout="vertical"
            hideRequiredMark
            form={form}
            onFinish={onFinish}
          >
            <Divider>Personal Information</Divider>
            <Row gutter={16}>
              <Col span={10}>
                <Form.Item
                  name="name"
                  label="Name"
                  style={{ marginRight: "20px" }}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  name="mobile"
                  label="Phone"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your phone number",
                    },
                    {
                      pattern: /^[0-9]{10}$/,
                      message: "Phone number must be 10 digits",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={10}>
                <Form.Item
                  name="gender"
                  label="Gender"
                  style={{ marginRight: "20px" }}
                >
                  <Select
                    listHeight={200}
                    placeholder="Select Gender"
                    optionFilterProp="children"
                    options={["MALE", "FEMALE", "OTHER"].map((option) => {
                      return { value: option, label: option };
                    })}
                  />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  name="dob"
                  label="Date of Birth"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <DatePicker
                    placeholder="Select Date of Birth"
                    format="DD MMM YYYY"
                    style={{ width: "100%" }}
                    disabledDate={disabledDate}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Drawer>
    </>
  );
};

export default EditUserDrawer;
