import React, { useState } from "react";
import { Progress, Typography, Button, Card, Image, Row, Col } from 'antd';
import  ProfileSurveyCategoryDrawer  from "../ProfileSurveyCategoryDrawer"
import { MdDoubleArrow } from "react-icons/md";
const { Title, Paragraph } = Typography;

const ProfileSurveyCategory = ({ categoryData }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const showDrawer = () => {
    setIsDrawerOpen(true);
  };

  const completePercent = parseInt((categoryData.response_count / categoryData.questions_count) * 100)
  return (
    <>
    <div className="profile-wrapper h-100 " onClick={showDrawer}>
      <div className="wrapper">
        <div className="img" onClick={showDrawer}>
          <img src={categoryData.img} alt=" profile survey" />
        </div>
        <div className="text mt-4 px-2">
          <h3 onClick={showDrawer}>{categoryData.name}</h3>
          {/* <Paragraph>
          {showMore ? categoryData.description : `${categoryData.description.substring(0, 45)}....`}
          <Button type="link" onClick={() => setShowMore(!showMore)}>{showMore ? "Show less" : "Show more"}</Button>
        </Paragraph> */}
        <Progress
          percent={completePercent}
          format={(percent) => `${categoryData.response_count} / ${categoryData.questions_count}`}
          style={{ width: "95%" }}
        />
        <span className="profile-btn"><MdDoubleArrow /></span>
        </div>

      </div>
    </div>
      {/* <Card title={<Title level={4} onClick={showDrawer}>{categoryData.name}</Title>} >
        <Row justify="center">
          <Col>
            <Image
              height="200px"
              preview={false}
              src={categoryData.img}
              onClick={showDrawer} />
          </Col>
        </Row>

        <Progress
          percent={completePercent}
          format={(percent) => `${categoryData.response_count} / ${categoryData.questions_count}`}
          style={{ width: "95%" }}
        />
        <Paragraph>
          {showMore ? categoryData.description : `${categoryData.description.substring(0, 45)}....`}
          <Button type="link" onClick={() => setShowMore(!showMore)}>{showMore ? "Show less" : "Show more"}</Button>
        </Paragraph>
      </Card> */}


      {isDrawerOpen &&
        <ProfileSurveyCategoryDrawer
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setIsDrawerOpen}
          categoryData={categoryData}
        />}
    </>
  );
};
export default ProfileSurveyCategory;
