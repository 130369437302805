import {
  Col,
  Form,
  Input,
  Row,
  DatePicker,
  Select,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateMe } from "../../redux/slice/authSlice";
import moment from "moment";

const ExtraInfo = () => {
  const [extrainfo, setExtraInfo] = useState(true);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { personalDetail } = useSelector(({ auth }) => auth);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const userData = localStorage.getItem("user");
    if (userData) {
      setUser(JSON.parse(userData));
    }
  }, []);

  const userinfo = user?.data?.user;
  const isDataComplete = userinfo?.mobile && userinfo?.gender && userinfo?.dob;

  useEffect(() => {
    if (userinfo && isDataComplete) {
      const age = moment().diff(moment(userinfo.dob, "DD MMM YY"), "years");
      if (age >= 18) {
        setExtraInfo(false);
      } else {
        message.error("You must be 18 years or older.");
      }
    }
  }, [userinfo]);

  const disabledDate = (current) => { 
    return current && current > moment().subtract(18, 'years').endOf('day');
  };

  const onFinish = (value) => { 
    if (value.dob) {
      value.dob = value.dob.format("DD MMM YY");  
    }
    dispatch(updateMe(value));
    const updatedUser = {
      ...user,  
      data: {
        ...user?.data,  
        user: {
          ...user?.data?.user,  
          mobile: value.mobile, 
          gender: value.gender,  
          dob: value.dob,  
        },
      },
    };
    localStorage.setItem("user", JSON.stringify(updatedUser));
    setExtraInfo(false);
  };

 

  useEffect(() => {
    if (Object.keys(personalDetail).length) {
      const dob = personalDetail?.dob
        ? moment(personalDetail.dob, "DD MMM YY, HH:mm:ss").format("DD MMM YY")
        : null;
      form.setFieldsValue({
        mobile: personalDetail?.mobile,
        gender: personalDetail?.gender,
        dob: dob ? moment(dob, "DD MMM YY") : null,
      });
    }
  }, [personalDetail, form]);


  if (!extrainfo) {
    return null;
  }

  return (
    <div className="login-card-wrapper extra-info-card">
      <div className="card-shadow"></div>
      <div className="login-card">
        <div className="login-card-header mb-4 d-flex align-items-center justify-content-between">
          <h5 className="h5 mb-0">User Information</h5>
        </div>
        <div className="form-card">
          <Form
            id="survey_submit_form"
            layout="vertical"
            hideRequiredMark
            form={form}
            onFinish={onFinish}
          >
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="mobile"
                  label="Phone Number"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your phone number",
                    },
                    {
                      pattern: /^[0-9]{10}$/,
                      message: "Phone number must be 10 digits",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="gender"
                  label="Gender"
                  rules={[
                    {
                      required: true,
                      message: "Please select your gender",
                    },
                  ]}
                >
                  <Select
                    listHeight={200}
                    placeholder="Select Gender"
                    optionFilterProp="children"
                    options={["MALE", "FEMALE", "OTHER"].map((option) => {
                      return { value: option, label: option };
                    })}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="dob"
                  label="Date of Birth"
                  rules={[
                    {
                      required: true,
                      message: "Please select your date of birth",
                    },
                  ]}
                >
                  <DatePicker
                    placeholder="Select Date of Birth"
                    format="DD MMM YYYY"
                    style={{ width: "100%" }}
                    disabledDate={disabledDate}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={10}>
                <Form.Item>
                  <button className="btn" type="primary" htmlType="submit">
                    Submit
                  </button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ExtraInfo;
