import React from 'react'
import TransactionHistory from './Transactions/transactionhistory'
import ListReferralsTable from './ReferTab/MyReferralTable'
import Transaction from "./Transactions"

const Earnings = () => {
  return (
    <div>
         
         <Transaction />
         {/* <div className="row">
          <div className="col-md-8">
          <ListReferralsTable  />  
          </div>
         </div>
        */}



    </div>
  )
}

export default Earnings
